import styled, { keyframes } from 'styled-components';
import devider from '../assets/border.png';

const CARD_URL = 'https://tnndonnqnixjfqlzqhxv.supabase.co/storage/v1/object/public/public/tarotcard/';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
  margin-top: 2rem;
`
const Cards = styled.div`
  position: relative;
  height: 17.4rem;
  width: 31.6rem;
`

const Card = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  width: 9.9rem;
  height: 16.4rem;
  margin-top: 2.746rem;
`;


const firstCardAnimate = keyframes`
  0% {
    left: 50%;
  } 
  100% {
    left: 0;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
`;

const secondCardAnimate = keyframes`
0% {
  left: 50%;
} 
100% {
  left: unset;
  right: 0;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}
`;

const ClosedCard = styled(Card)`
  z-index: 1;

`
const FirstCard = styled(Card)`
  &.isActive {
    animation: ${firstCardAnimate} .75s ease;
    -webkit-animation: ${firstCardAnimate} .75s ease;
    -moz-animation: ${firstCardAnimate} .75s ease;
    -ms-animation: ${firstCardAnimate} .75s ease;
    -o-animation: ${firstCardAnimate} .75s ease;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
  }
`

const SecondCard = styled(Card)`
  &.isActive {
    animation: ${secondCardAnimate} .75s ease;
    -webkit-animation: ${secondCardAnimate} .75s ease;
    -moz-animation: ${secondCardAnimate} .75s ease;
    -ms-animation: ${secondCardAnimate} .75s ease;
    -o-animation: ${secondCardAnimate} .75s ease;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
  }
`;

const ThirdCard = styled(Card)`
`

const CardImg = styled.img`
  width: 9.9rem;
  height: 16.4rem;
`;

const LeftCount = styled.div`
  position: absolute;
  top: -0.74rem;
  right: -1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 2.3rem;
  line-height: 3.2rem;
  text-align: center;
  color: #fff;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 1.6rem;
  color: #FFFFFF;
  background-color: var(--color-red);
  z-index: 500;
`;

const TxtClickIt = styled.p`
  text-align: center;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.7rem;
  text-align: center;
  color: var(--color-gray9);
  margin-top: 2rem;
`;

const CardContainer = ({leftCardNum, onCardClick, cards}) => (
    <Section isDone={leftCardNum === 0}>
      <Devider src={devider}/>
      <Cards>
        {leftCardNum > 0 && <ClosedCard onClick={onCardClick}>
          <CardImg src={`${CARD_URL}24.png`}/>
          {leftCardNum > 0 && <LeftCount>{leftCardNum}</LeftCount>}
        </ClosedCard>}
        <FirstCard className={leftCardNum <= 2 ? 'isActive' : ''} >
          <CardImg src={`${CARD_URL}${cards[0]}.png`}/>
        </FirstCard>
        <SecondCard className={leftCardNum <= 1 ? 'isActive' : ''}>
          <CardImg src={`${CARD_URL}${cards[1]}.png`} />
        </SecondCard>
        <ThirdCard>
          <CardImg src={`${CARD_URL}${cards[2]}.png`} />
        </ThirdCard>
      </Cards>
      {leftCardNum > 0 && <TxtClickIt onClick={onCardClick}>△<br/>{`눌러서 카드 ${leftCardNum}장 뽑기`}</TxtClickIt>}
    </Section>
    );

export default CardContainer;