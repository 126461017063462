import { useEffect, useState, useMemo } from 'react';
import amplitude from 'amplitude-js';
import Header from './components/Header';
import CounselorContent from './components/CounselorContent';
import CardContainer from './components/CardContainer';
import ModalContainer from './components/ModalContainer';

import './App.css';
import ContactSoon from './components/ContactSoon';

function App() {
  const [leftCardNum, setLeftCardNum] = useState(3);
  const [isReady, setIsReady] = useState(false);
  const counselorName = '타로언니';
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'onboarding_pickcard' });
  }, []);

  const rndRange = totalIndex => {
    const randomIndexArray = [];
    while (randomIndexArray.length < totalIndex) {
      const randomNum = Math.floor(Math.random() * totalIndex);
      if (randomIndexArray.indexOf(randomNum) === -1) {
        randomIndexArray.push(randomNum);
      }
    }
    return randomIndexArray;
  };

  const userRedirect = () => {
    window.open(`https://www.tarot-sister.com/${window.location.search}`, '_self', 'noopener', 'noreferrer');
  };

  const readyStateHandler = () => {
    setIsReady(!isReady);
    amplitude.getInstance().logEvent('view_result', { referral: 'onboarding_pickcard' });
  };

  const randomNums = useMemo(() => rndRange(21), []);
  const cards = [randomNums[0] + 1, randomNums[1] + 1, randomNums[2] + 1];

  const cardClickHandler = event => {
    amplitude.getInstance().logEvent('open_card', { referral: 'onboarding_pickcard' });
    setLeftCardNum(prev => prev - 1);
  };

  return (
    <>
      <Header />
      <CounselorContent leftCardNum={leftCardNum} counselorName={counselorName} isReady={isReady} />
      {isReady && <ContactSoon />}
      <CardContainer leftCardNum={leftCardNum} onCardClick={cardClickHandler} cards={cards} />
      <ModalContainer
        isOpen={leftCardNum === 0 && !isReady}
        cards={cards}
        onReady={readyStateHandler}
        isReady={isReady}
        userRedirect={userRedirect}
      />
    </>
  );
}

export default App;
