import styled from 'styled-components';
import devider from '../assets/border.png';
import sampleProfile from '../assets/tarot-icon.png';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

const CounselorProfileImg = styled.img`
  width: 10rem;
  height: 11.2rem;
  margin: 2rem 0 0.8rem 0;
`;

const CounselorName = styled.span`
  font-family: 'Heir of Light OTF';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.2rem;
  margin-bottom: 1.2rem;
`;

const MainContent = styled.p`
  width: 28rem;
  font-family: 'Heir of Light OTF';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.7rem;
  text-align: center;
`;

const FiveMinute = styled.div`
  flex-direction: row;
  align-items: flex-start;
  padding: 0.6rem 1.4rem;
  margin-top: 1.2rem;
  margin-bottom: 2rem;
  gap: 0.4rem;
  width: 8.8rem;
  height: 3rem;
  background: #9f7c44;
  border-radius: 2.3rem;
  color: #fff;
  line-height: 3rem;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.8rem;
  text-align: center;
`;

const CounselorContent = ({ leftCardNum, counselorName, isReady }) => {
  const mainContent = {
    3: (
      <MainContent>
        나를 힘들게 하는
        <br /> 고민거리를 생각하면서
        <br /> 타로 카드[{leftCardNum}장]을 뽑아보세요"
      </MainContent>
    ),
    2: <MainContent>고민거리를 생각하면서 타로 카드[{leftCardNum}장]을 더 뽑아보세요"</MainContent>,
    1: (
      <MainContent>
        마지막 카드를 뽑고
        <br />
        저의 해석을 들어보세요
      </MainContent>
    ),
    0: (
      <MainContent>
        마지막 카드를 뽑고
        <br />
        저의 해석을 들어보세요
      </MainContent>
    ),
    ready: (
      <MainContent>
        잠시 기다리시면 곧 연락을 드릴게요. <br />
        [3분간 무료]로 고민을 나눠봐요
      </MainContent>
    ),
  };

  return (
    <Section>
      <Devider src={devider} />
      <CounselorProfileImg src={sampleProfile}></CounselorProfileImg>
      <CounselorName>{counselorName}</CounselorName>
      {!isReady ? mainContent[leftCardNum] : mainContent.ready}
      {isReady && <FiveMinute>3분간 무료</FiveMinute>}
    </Section>
  );
};

export default CounselorContent;
