import { useEffect } from 'react';
import styled from 'styled-components';
import devider from '../assets/border.png';
import phoneIco from '../assets/icon-call.png';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

const PhoneNum = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: 173px;
  height: 36px;
  background: #09b5a0;
  border-radius: 10000px;
  margin-top: 2rem;
`;

const PhoneIco = styled.img`
  width: 2rem;
  height: 2rem;
`;

const PhoneNumTxt = styled.span`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-left: 0.8rem;
  color: #ffffff;
`;

const Notice = styled.p`
  width: 16.9rem;
  height: 5.5rem;
  margin-top: 0.8rem;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.7rem;
  text-align: center;
  color: #000000;
`;

const ContactSoon = ({ isReady }) => {
  useEffect(() => {
    fbq('track', 'CompleteRegistration', { value: 100, currency: 'KRW' });
  }, []);
  return (
    <Section>
      <Devider src={devider} />
      <PhoneNum>
        <PhoneIco src={phoneIco} />
        <PhoneNumTxt>02-6953-6510</PhoneNumTxt>
      </PhoneNum>
      <Notice>
        곧 위 번호로 상담사님의
        <br /> 상담 전화가 올 거에요.
      </Notice>
    </Section>
  );
};

export default ContactSoon;
