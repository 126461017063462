import { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';

import styled from 'styled-components';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import checkedButton from '../assets/checked.svg';
import unCheckedButton from '../assets/unChecked.svg';
import { fetchUtmMap, UtmKey } from '../utils/datautils';

const ModalContainer = ({ isOpen, cards, isReady, onReady, userRedirect }) => {
  const [typedPhone, setTypedPhone] = useState(false);
  const [phoneNum, setPhoneNum] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [showAgreement, setShowAgreement] = useState(false);
  const [submitStarted, setSubmitStarted] = useState(false);

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    if (isOpen && !typedPhone) {
      amplitude.getInstance().logEvent('view_input_phone', {
        referral: 'onboarding_pickcard',
      });
    }
  }, [isOpen, typedPhone]);

  const URL = `https://api.tarosister.com/api/v1/user/register`;

  const phoneSubmitHandler = () => {
    amplitude.getInstance().logEvent('submit_phone', {
      phone: phoneNum,
      referral: 'onboarding_pickcard',
    });

    if (!isPhoneNumValid(phoneNum) || !isChecked) return;
    setTypedPhone(prev => true);
  };

  const userRegisterHandler = async () => {
    amplitude.getInstance().logEvent('click_register', {
      phone: phoneNum,
      referral: 'onboarding_pickcard',
    });

    if (submitStarted) return;
    setSubmitStarted(prev => true);
    if (!isPhoneNumValid(phoneNum) || !isChecked) return;
    if (isReady) return;

    let utmMap = {};
    try {
      if (localStorage.getItem(UtmKey)) {
        utmMap = JSON.parse(localStorage.getItem(UtmKey));
      } else {
        utmMap = fetchUtmMap();
      }

      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({ phone: phoneNum, cards, is_test: true, referral: 'onboarding_pickcard', ...utmMap }),
        headers: {
          'Content-type': 'application/json',
        },
      });

      if (response.status === 400) {
        userRedirect(); // existingUser redirect to main service
      } else if (response.status > 400) {
        throw new Error();
      } else {
        onReady();
        amplitude.getInstance().logEvent('register', {
          phone: phoneNum,
          referral: 'onboarding_pickcard',
        });
        const identify = new amplitude.Identify().setOnce('phone', phoneNum);
        amplitude.getInstance().identify(identify);
        fbq('track', 'Lead', { value: 10, currency: 'KRW' });
      }
    } catch (error) {
      console.log(error);
      toast.error('오류가 발생했습니다.');
    } finally {
      setSubmitStarted(prev => false);
    }
  };

  const clickAvailableHandler = () => {
    userRegisterHandler();
  };

  const clickNotAvailableHandler = () => {
    amplitude.getInstance().logEvent('click_no', {
      phone: phoneNum,
      referral: 'onboarding_pickcard',
    });

    setTimeout(() => {
      userRedirect();
    }, 2000);
  };

  const checkBoxChangeHandler = event => {
    setIsChecked(prev => !prev);
  };

  const phoneNumChangeHandler = event => {
    setPhoneNum(prev => event.target.value);
    if (isPhoneNumValid(event.target.value)) {
      setShowAgreement(prev => true); // show and leave it at first validInput
    }
  };

  const isPhoneNumValid = phoneNum => phoneNum.length === 11;

  return (
    <>
      <Modal
        appElement={document.querySelector('#root')}
        isOpen={isOpen}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 100,
          },
          content: {
            width: '100%',
            padding: 0,
            minHeight: '15rem',
            borderRadius: '2.4em 2.4rem 0 0',
            inset: 'auto 0 0 50%',
            transform: 'translate(-50%, 0)',
          },
        }}>
        <MainContent>
          <Header>{typedPhone ? '지금 통화가 가능하신가요?' : '3분 무료 타로 상담받기'}</Header>
          <p style={{ margin: '1.6rem 0', width: '100%', fontSize: '1.6rem', lineHeight: '120%' }}>
            5년 경력 타로 마스터가
            <br /> 고객님이 뽑으신 타로 결과를 해석해드립니다.
          </p>
          {!typedPhone && (
            <>
              <SupportTxt
                style={{ marginTop: '2.4rem' }}
                isActive={phoneNum.length > 0}
                className={phoneNum.length === 0 ? 'hidden' : ''}>
                휴대폰 번호 입력
              </SupportTxt>
              <Input
                placeholder="휴대폰 번호 입력"
                value={phoneNum}
                onChange={phoneNumChangeHandler}
                inputMode="numeric"></Input>
              <SupportTxt style={{ marginTop: '0.4rem' }}>'-'없이 숫자만</SupportTxt>
              {showAgreement && (
                <AgreeContainer>
                  <CheckBox type="checkbox" id="checkbox" checked={isChecked} onChange={checkBoxChangeHandler} />
                  <CheckBoxLabel htmlFor="checkbox">
                    <LabelContainer>
                      <LabelTxtMain>(필수) 마케팅 수집 이용 동의</LabelTxtMain>
                      <LabelTxtSub>입력하신 번호로 무료 상담 전화가 갈 거에요</LabelTxtSub>
                    </LabelContainer>
                    <RealCheckBox></RealCheckBox>
                  </CheckBoxLabel>
                </AgreeContainer>
              )}
              <Button isActive={isPhoneNumValid(phoneNum) && isChecked} onClick={phoneSubmitHandler}>
                입력 완료
              </Button>
            </>
          )}

          {typedPhone && (
            <>
              {submitStarted && (
                <p style={{ marginTop: '2.4rem', width: '100%', fontSize: '1.6rem', textAlign: 'center' }}>
                  카드 리딩 중...
                </p>
              )}
              <div style={{ marginTop: '2rem' }}>
                <div style={{ width: '100%', display: 'flex', columnGap: '15px' }}>
                  <Button
                    style={{ backgroundColor: '#fff', color: '#000', border: '1px solid black', fontSize: '2rem' }}
                    onClick={clickNotAvailableHandler}>
                    아니오
                  </Button>
                  <Button style={{ backgroundColor: '#222529', fontSize: '2rem' }} onClick={clickAvailableHandler}>
                    가능합니다
                  </Button>
                </div>
              </div>
            </>
          )}
        </MainContent>
      </Modal>
    </>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  transition: all ease 1s;
`;

const Header = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  align-self: baseline;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid #b4a947;
  height: 4.2rem;
  width: 100%;
  padding: 0.8rem 1.2rem 0.8rem 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &::placeholder {
    color: #7f7573;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid #362f2d;
  }
`;

const SupportTxt = styled.span`
  font-family: 'Roboto';
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.04rem;
  color: #7f7573;
  transition: all 0.3s linear;

  &.hidden {
    display: none;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 5.6rem;
  background-color: ${props => (props.isActive ? 'var(--main-color)' : 'var(--color-gray4)')};
  border: none;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 5.6rem;
  text-align: center;
  color: #fff;
  margin-top: 1.2rem;
  cursor: pointer;
`;

const AgreeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckBoxLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 5.3rem;
  margin-top: 2rem;
`;

const LabelTxtMain = styled.span`
  font-family: 'Inter';
  width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.8rem;
  color: #353a40;
`;

const LabelTxtSub = styled.span`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.3rem;
  color: #353a40;
  margin-top: 0.4rem;
`;

const RealCheckBox = styled.div`
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  background-image: url(${unCheckedButton});
  background-size: cover;
`;

const CheckBox = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;

  &:checked {
    & + ${CheckBoxLabel} ${RealCheckBox} {
      background-image: url(${checkedButton});
      background-size: cover;
    }
  }
`;

export default ModalContainer;
